.nav-tabset {
    margin-bottom: -17px;

    .nav-item {
        margin: 0 0.8vw 0 0;

        &.active {
            .nav-link {
                color: $gray-800;
                font-weight: $font-weight-bold;

                &:before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .nav-link {
        border: none;
        background: none;
        padding: 0 0 17px;
        position: relative;
        font-size: 1.6rem;
        font-weight: $font-weight-normal;

        &:before {
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            height: 3px;
            box-shadow: 0px 1px 3px rgba(53, 145, 255, 0.6);
            border-radius: 2px 2px 0 0;
            background-color: $primary;
            content:'';
            opacity: 0;
            visibility: hidden;
            z-index: 5;
            @include transition(opacity visibility);
        }

        &.active {
            color: $gray-800;
            font-weight: $font-weight-bold;

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }

        &:hover {
            color: $gray-800;

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }
    } 

    &.size-md {
        .nav-link {
            font-size: 1.4rem;
        }
    }
}