.page-item {
    margin: 0 4px;

    .page-link {
        min-width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $pagination-border-radius;
        line-height: 1;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &:not(:first-child) {
        .page-link {
            margin-left: 0;
        }
    }
}