.breadcrumb {
    font-weight: $font-weight-medium;

    a {
        text-decoration: none;
        color: $breadcrumb-active-color;

        &:hover {
            color: $primary;
        }
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    margin-top: -1px;
}