.modal {
  .subtitle {
    padding-bottom: 21px;
  }

  .card-header {
    padding-bottom: 14px;
    padding-top: 19px;
  }

  .nav-tabset {
    margin-bottom: -14px;

    .nav-item {
      margin: 0 1.5vw 0 0;
    }

    .nav-link {
      font-size: 1.4rem;
      padding-bottom: 14px;
    }
  }

  .chip-item .property-item {
    font-size: 1.3rem;
  }

  .graphic-block {
    margin: 0 0 28px;
  }

  div.dataTables_wrapper {
    padding: 0 14px 0 0;
  }
}

.modal-header {
  align-items: flex-start;

  .button-holder {
    display: flex;
    flex-direction: column;
  }

  .btn-close {
    margin-bottom: 15px;
    position: relative;
    top: 4px;
    right: 8px;
    padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  }
}

.modal-title {
  font-weight: $font-weight-normal;
  color: $secondary;
}

.modal-body {
  font-size: 1.1rem;
  padding-top: 0;

  p {
    line-height: 1.55;
    margin: 0 0 19px;
  }
}

.small-modal {
  hr {
    margin: 0;
    color: $blue-200;
    opacity: 1;
  }

  .modal-title {
    font-weight: $font-weight-medium;
    color: $gray-800;

    .badge {
      margin: 0 0 0 4px;
    }
  }

  .modal-header {
    padding-top: 15px;
    padding-bottom: 14px;
  }

  .modal-body {
    padding-bottom: 24px;
  }

  .btn-close {
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    // background: none;
    // width: auto;
    // height: auto;
    // filter: none;
    // opacity: 0.41;
    // top: -5px;
    // @include transition(opacity);

    // &:hover {
    //   opacity: 1;
    // }
  }

  .modal-footer {
    background: none;
    border-top: 1px solid $blue-200;
    padding-top: 12px;
    padding-bottom: 12px;

    .btn {
      font-size: 1.4rem;
      padding-top: 7px;
      padding-bottom: 6px;

      &.btn-primary {
        min-width: 127px;
      }
    }
  }
}
