.progress {
    &.progress-sm {
        height: 12px;
        border-radius: 1px;
    }

    &.progress-md {
      height: 13px;
      border-radius: 0;
  }
}

.progress-bar {
  transition: none;
  
  a {
    text-decoration: none;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:hover {
      color: $progress-bar-color;
    }
  }
}

.multi-progress {
    color: $gray-650;
    font-size: 1rem;
  
    .progress-row {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
    }
  
    .title {
      width: 31%;
      padding: 0 10px 0 0;

      @include media-breakpoint-up(xl) {
        width: 33%;
      }
    }
  
    .progress {
      flex-grow: 1;
    }
  
    .value {
      width: 32px;
      padding: 0 0 0 10px;
      flex-shrink: 0;
    }
}