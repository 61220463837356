.card {
  width: 100%;

  .card-img-top {
    margin-bottom: 2.1rem;
    display: block;
    width: 100%;
    border-radius: 0;
  }

  // 2-04
  .card-image {
    border: 1px solid $blue-200;
    border-radius: 4px;
    margin-bottom: 2.1rem;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .card-title {
    line-height: 1.35;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    a {
      color: $gray-800;

      &:hover {
        text-decoration: underline;
        color: $primary;
      }
    }
  }

  .card-opener {
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
    color: $gray-800;
    border-width: 1px 0;

    i {
      @include transition(transform);
    }

    &[aria-expanded='true'] {
      i {
        transform: rotate(180deg);
      }
    }

    &.card-footer {
      border-width: 1px 0 0;
    }

    &.card-header {
      border-width: 0 0 1px;
    }
  }

  .card-footer {
    padding-bottom: 1.8rem;
    padding-top: 2.1rem;
  }

  .card-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 1.3rem;

    a {
      font-weight: $font-weight-medium;
    }

    h3 {
      margin-right: 10px;
    }
  }

  .product-block & {
    box-shadow: none;
  }

  // 2-08
  &.small-product {
    .card-image {
      width: 48px;
      flex-shrink: 0;
      margin: 0 16px 0 0;
    }

    // .card-img-top {
    //   width: 48px;
    //   flex-shrink: 0;
    //   margin: 0 16px 0 0;
    // }
  }

  &.bg-gray-dark {
    border-color: $gray-800;
  }
}

.card-body {
  &.smaller {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

////////////
//#2-03
.empty-state-container {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  min-height: 18.166667rem; //218
}

.empty-state-block {
  text-align: center;
  font-size: 1.1666667rem; //14

  .image-holder {
    margin-bottom: 1rem;
  }

  .title {
    text-transform: none;
    margin-bottom: 0.5rem;
  }
}
////////////

////////////
//#3-01

.card {
  &.sticky-card {
    &.bt-0 {
      border-top: none;

      &::before,
      &::after {
        margin: 0;
      }
    }
  }
}
////////////

.tab-pane {
  .card {
    &:not(.product-card) {
      z-index: 2;
    }
  }
}