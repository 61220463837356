// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff;

$gray-100: #f8f9fa !default;
$gray-200: #F5F5F5;
$gray-250: #D6D6D6;
$gray-300: #AEAEAE;
$gray-400: #96A6BB;
$gray-450: #979797;
$gray-500: #78828A;
$gray-600: #6D6D6D;
$gray-650: #747474;
$gray-700: #524e69;
$gray-800: #3D3D3D;
$gray-900: #212529 !default;

$blue-100: #F1F4F9;
$blue-200: #E1E6ED;
$blue-300: #E7EAEF;
$blue-400: #BBE5EE;
$blue-700: #3A394B;
$blue-800: #222035;
$blue-900: #0F0B22;

$black:    #000;
$red-alt:  #EE494F;

// scss-docs-start color-variables
$blue:    #3591FF;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #F14046;
$orange:  #fd7e14 !default;
$yellow:  #EC9725;
$teal:   #5ABE88;
$green:    #37AB6C;
$cyan:    #0dcaf0 !default;
$light-blue: #CEF4FC;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-500,
  "gray-dark":  $gray-800,
  "medium-gray":  $gray-400
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary:       $blue;
$secondary:     $gray-500;
$success:       $green;
$info:          $cyan !default;
$warning:       $yellow;
$danger:        $red !default;
$light:         $blue-200;
$dark:          $blue-800;
$darker:        $gray-300;
$pale:          $blue-100;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "darker":     $darker,
  "pale":       $pale,
  "subtle":     map-get($colors, "gray"),
  "available":  $teal,
  "gray-dark":  map-get($colors, "gray-dark"),
  "medium-gray":  map-get($colors, "medium-gray")
);
// scss-docs-end theme-colors-map

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              true;
$enable-shadows:              true;
$enable-gradients:            false !default;
$enable-transitions:          true;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        false;
$enable-grid-classes:         true;
$enable-container-classes:    true;
$enable-cssgrid:              false !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     true;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true;

$variable-prefix:             bs-; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix;


// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  180;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $black;
$yiq-text-light:            $white;

$grid-columns: 12;
$grid-gutter-width: 10px;

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 320px,
        md: 768px,
        lg: 1024px,
        xl: 1200px,
        xxl: 1400px
);

$container-max-widths: (
        sm: 1014px + ($grid-gutter-width * 2),
);

// spacing
//
// control the default styling of most bootstrap elements by modifying these
// variables. mostly focused on spacing.
// you can add more entries to the $spacers map, should you need more variation.

// stylelint-disable-next-line scss/dollar-variable-default
// prettier-ignore-start
$spacer: 10px;
$spacers: (
        0: 0,
        1: ($spacer * 0.4), //4px
        2: ($spacer * 0.5), //5px
        3: ($spacer * 0.8), //8px
        4: $spacer,
        5: ($spacer * 1.4), //14px,
        6: ($spacer * 1.6), //16px
        18: ($spacer * 1.8), //18px
        25: ($spacer * 2.5), //25px
        7: ($spacer * 2), //20px
        8: ($spacer * 3.2), //32px
        9: ($spacer * 4.2) //42px
);

// body
//
// settings for the `<body>` element.

$body-bg: $blue-100;
$body-color: $gray-600;

// Links
//
// Style anchor elements.

$link-color:                   $primary;
$link-decoration:              underline;
$link-hover-color:             $link-color;
$link-hover-decoration:        none;

$border-color:               $blue-200;

// border radius
$rounded-circle: 50%;
$border-radius:  1rem;
$border-radius-sm:  0.4rem;
$border-radius-md:  0.8rem;

$border-width: 1px;

$box-shadow:                  0 0 0 rgba(0, 0, 0, 0);
$box-shadow-sm:               0 0 0 rgba(0, 0, 0, 0);
$box-shadow-lg:               0 0 0 rgba(0, 0, 0, 0);
$box-shadow-inset:            0 0 0 rgba(0, 0, 0, 0);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif ;
$font-family-serif:          'Times New Roman', Times, serif;
$font-family-secondary:       $font-family-serif;
$font-family-base:            $font-family-sans-serif;
$base-font-path: '../fonts';
$fa-font-path: "../fonts/fontawesome";
// stylelint-enable value-keyword-case

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          800;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.2;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              12px;
$font-size-base:              1.2rem;

$h1-font-size:                $font-size-base * 1.66667; //20px
$h2-font-size:                $font-size-base * 1.333334; //16px
$h3-font-size:                $font-size-base * 1.16667; //14px
$h4-font-size:                $font-size-base;
$h5-font-size:                $font-size-base * 0.916667;
$h6-font-size:                $font-size-base * 0.833334;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size
) !default;

$headings-margin-bottom:      1.7rem;
$headings-font-weight:        $font-weight-medium;
$headings-line-height:        1.2;
$headings-color:              $gray-800;

$paragraph-margin-bottom:     2rem;

// Base transition function
$transition-duration-base: 0.35s;
$transition-easing-base: cubic-bezier(0.3, 0.86, 0.36, 0.95);

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     0.7rem;
$accordion-padding-x:                     1.8rem;
$accordion-bg:                            transparent;
$accordion-border-width:                  0;
$accordion-border-color:                  $blue-200;
$accordion-border-radius:                 0;
$accordion-inner-border-radius:           $border-radius;

$accordion-body-padding-y:                1rem;
$accordion-body-padding-x:                0;

$accordion-button-bg:                     $white;
$accordion-button-active-bg:              $white;
$accordion-button-color:                  $body-color;
$accordion-button-active-color:           $body-color;

$accordion-button-focus-border-color:     $accordion-border-color;
$accordion-button-focus-box-shadow:       0 0 0 rgba(0, 0, 0, 0);

$accordion-icon-width:                    1.6rem;
$accordion-icon-color:                    $gray-800;
$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                none;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='#3d3d3d'><path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='#3d3d3d'><path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z'/></svg>");
// scss-docs-end accordion-variables


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                0;
$nav-link-padding-x:                0;
$nav-link-font-size:                1.7rem;
$nav-link-font-weight:              $font-weight-medium;
$nav-link-color:                    $gray-500;
$nav-link-hover-color:              $primary;

// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  2rem;
$navbar-padding-x:                  1rem;

$navbar-nav-link-padding-x:         0;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   auto;
$navbar-brand-height:               auto;
$navbar-brand-padding-y:            0;
$navbar-brand-margin-end:           0;

$navbar-toggler-padding-y:          0.7rem;
$navbar-toggler-padding-x:          0.7rem;
$navbar-toggler-font-size:          1.1rem;
$navbar-toggler-border-radius:      $border-radius-md;
$navbar-toggler-focus-width:        $border-width;
$navbar-toggler-transition:         none;

$navbar-light-color:                $gray-500;
$navbar-light-hover-color:          $gray-500;
$navbar-light-active-color:         $gray-500;

// scss-docs-start navbar-dark-variables
$navbar-dark-color:                 rgba($white, 0.6);
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
// scss-docs-end navbar-dark-variables
// scss-docs-end navbar-variables


// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                125px;
$dropdown-padding-x:                16px;
$dropdown-padding-y:                9px;
$dropdown-font-size:                1.1rem;
$dropdown-color:                    $gray-800;
$dropdown-bg:                       $white;
$dropdown-border-color:             $gray-500;
$dropdown-border-radius:            4px;
$dropdown-border-width:             0;
$dropdown-divider-bg:               $blue-200;
$dropdown-divider-margin-y:         5px;
$dropdown-box-shadow:               0px 10px 20px 0px rgba(0, 0, 0, 0.20);

$dropdown-link-color:               $gray-500;
$dropdown-link-hover-color:         $gray-800;
$dropdown-link-hover-bg:            transparent;

$dropdown-link-active-color:        $dropdown-link-hover-color;
$dropdown-link-active-bg:           $dropdown-link-hover-bg;

$dropdown-item-padding-y:           5px;
$dropdown-item-padding-x:           0;

$dropdown-header-color:             $white;
$dropdown-header-padding-x:         $dropdown-padding-x;
$dropdown-header-padding-y:         9px;
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color:               $navbar-dark-color;
$dropdown-dark-bg:                  $blue-900;
$dropdown-dark-border-color:        transparent;
$dropdown-dark-box-shadow:          none;
$dropdown-dark-link-color:          $navbar-dark-color;
$dropdown-dark-link-hover-color:    $navbar-dark-hover-color;
$dropdown-dark-link-hover-bg:       $blue-700;
$dropdown-dark-link-active-color:   $navbar-dark-active-color;
$dropdown-dark-link-active-bg:      $dropdown-dark-link-hover-bg;
// scss-docs-end dropdown-dark-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding:               20px;

$modal-title-line-height:           $line-height-base;

$modal-content-color:               $secondary;
$modal-content-bg:                  $white;
$modal-content-border-color:        $border-color;
$modal-content-border-width:        $border-width;
$modal-content-border-radius:       $border-radius-md;
$modal-content-box-shadow-sm-up:    0px 4px 5px rgba(0, 0, 0, 0.1);

$modal-backdrop-bg:                 $gray-800;
$modal-backdrop-opacity:            .7;

$modal-header-border-width:         0;
$modal-header-padding-y:            $modal-inner-padding;
$modal-header-padding-x:            $modal-inner-padding;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-footer-bg:                   $secondary;

$modal-sm:                           500px;
$modal-md:                          746px;
$modal-lg:                          1180px;
$modal-xl:                          1292px;

// scss-docs-end modal-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y:               1.2rem;
$offcanvas-padding-x:               0;
$offcanvas-horizontal-width:        242px;
$offcanvas-vertical-height:         30vh;
$offcanvas-transition-duration:     .3s;
$offcanvas-border-color:            $modal-content-border-color;
$offcanvas-border-width:            0;
$offcanvas-box-shadow:              0 0 0 rgba(0, 0, 0, 0);
// scss-docs-end offcanvas-variables

// Progress bars

// scss-docs-start progress-variables
$progress-height:                   20px;
$progress-font-size:                1rem;
$progress-bg:                       $blue-100;
$progress-border-radius:            $border-radius-sm;
$progress-box-shadow:               0 0 0 rgba(0, 0, 0, 0);
$progress-bar-color:                $white;
$progress-bar-bg:                   $primary;
// scss-docs-end progress-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size:                   1rem;
$badge-font-weight:                 $font-weight-medium;
$badge-color:                       $white;
$badge-padding-y:                   0.2rem;
$badge-padding-x:                   0.2rem;
$badge-border-radius:               2px;
// scss-docs-end badge-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              1.1rem;
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding-x:         0.6rem;
$breadcrumb-margin-bottom:          1.7rem;
$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-300;
$breadcrumb-active-color:           $gray-500;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 256 512'><path d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z' fill='#{$breadcrumb-divider-color}'/></svg>");
$breadcrumb-border-radius:          0;
// scss-docs-end breadcrumb-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         6px;
$input-btn-padding-x:         12px;
$input-btn-font-family:       $font-family-base;
$input-btn-font-size:         1.1rem;
$input-btn-line-height:       1;

$input-btn-focus-width:         1px;
$input-btn-focus-box-shadow:    0 0 0 rgba(0, 0, 0, 0);

$input-btn-border-width:      $border-width;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-family:             $input-btn-font-family;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;
$btn-white-space:             nowrap;

$btn-border-width:            $input-btn-border-width;

$btn-font-weight:             $font-weight-medium;
$btn-box-shadow:              0 0 0 rgba(0, 0, 0, 0);
$btn-focus-width:             $input-btn-focus-width;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$btn-active-box-shadow:       0 0 0 rgba(0, 0, 0, 0);

$btn-link-color:              $gray-800;
$btn-link-hover-color:        $primary;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius-sm;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, padding .15s ease-in-out;
// scss-docs-end btn-variables

// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer * 2;
$card-spacer-x:                     $spacer * 2;
$card-title-spacer-y:               $spacer * 2;
$card-border-width:                 $border-width;
$card-border-color:                 $border-color;
$card-border-radius:                $border-radius-md;
$card-box-shadow:                   0px 1px 1px rgba(0, 0, 0, 0.05);
$card-inner-border-radius:          $border-radius-md;
$card-cap-padding-y:                $spacer * 1.5;
$card-cap-padding-x:                $card-spacer-x;
$card-cap-bg:                       transparent;
$card-cap-color:                    $card-border-color;
$card-height:                       auto;
$card-color:                        $body-color;
$card-bg:                           $white;
// scss-docs-end card-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:        13px;
$table-cell-padding-x:        10px;

$table-cell-vertical-align:   middle;

$table-color:                 var(--#{$prefix}gray-800);
$table-bg:                    transparent;
$table-accent-bg:             transparent;

$table-th-font-weight:        $font-weight-medium;

$table-striped-color:         $table-color;
$table-striped-bg:            rgba($pale, 0.5);

$table-active-bg:             rgba($pale, 0.5);

$table-hover-color:           $table-color;
$table-hover-bg-factor:       1;
$table-hover-bg:              rgba($blue-200, 0.5);

$table-border-width:          $border-width;
$table-border-color:          var(--#{$prefix}border-color);

$table-striped-order:         odd;

$table-group-separator-color: currentcolor !default;

$table-bg-scale:              -80%;

// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
) !default;
// scss-docs-end table-loop

// Close

// scss-docs-start close-variables
$btn-close-width:            1.75rem;
$btn-close-height:           $btn-close-width;
$btn-close-padding-x:        .25em;
$btn-close-padding-y:        $btn-close-padding-x;
$btn-close-color:            $gray-400; //$gray-800;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
$btn-close-opacity:          1;
$btn-close-hover-opacity:    0.8; //0.5
$btn-close-focus-opacity:    0.8; //0.5

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size:                 1.1rem;
$tooltip-max-width:                 141px;
$tooltip-color:                     $white;
$tooltip-bg:                        $gray-800;
$tooltip-border-radius:             $border-radius-sm;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 $spacer * 0.4;
$tooltip-padding-x:                 0.9rem;
$tooltip-margin:                    0;

$tooltip-arrow-width:               1.1rem;
$tooltip-arrow-height:              0.9rem;
// fusv-disable

// fusv-enable
// scss-docs-end tooltip-variables

// Toasts

// scss-docs-start toast-variables
$toast-color:                       $body-color;

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y:               $spacer;
$alert-padding-x:               $spacer;
$alert-border-radius:           $border-radius;
$alert-link-font-weight:        $font-weight-bold;
$alert-border-width:            $border-width;
// scss-docs-end alert-variables

// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y:              5px;
$pagination-padding-x:              2px;

$pagination-font-size:              1.1rem;

$pagination-color:                  $secondary;
$pagination-bg:                     $white;
$pagination-border-radius:          $border-radius-sm;
$pagination-border-width:           $border-width;
$pagination-margin-start:           ($pagination-border-width * -1) !default;
$pagination-border-color:           $border-color;

$pagination-focus-color:            $white;
$pagination-focus-bg:               $primary;

$pagination-hover-color:            $white;
$pagination-hover-bg:               $primary;
$pagination-hover-border-color:     $primary;

$pagination-active-color:           $white;
$pagination-active-bg:              $primary;
$pagination-active-border-color:    $primary;

$pagination-disabled-color:         rgba($secondary, 0.2);
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $pagination-border-color;

$pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$pagination-border-radius-sm:       $border-radius-sm;
$pagination-border-radius-lg:       $border-radius-md;
// scss-docs-end pagination-variables

// Popovers

// scss-docs-start popover-variables
$popover-font-size:                 $font-size-base;
$popover-bg:                        $white;
$popover-max-width:                 100%;
$popover-border-width:              $border-width;
$popover-border-color:              transparent;
$popover-border-radius:             $border-radius-sm;
$popover-inner-border-radius:       $border-radius-sm;
$popover-box-shadow:                0px 26px 50px rgba(0, 0, 0, 0.2);

$popover-header-font-size:          1rem;
$popover-header-bg:                 $gray-800;
$popover-header-color:              $white;
$popover-header-padding-y:          10px;
$popover-header-padding-x:          16px;

$popover-body-color:                $body-color;
$popover-body-padding-y:            0;
$popover-body-padding-x:            16px;

$popover-arrow-width:               14px;
$popover-arrow-height:              11px;
// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color:               $popover-bg;
// fusv-enable

// Forms

// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom:              ($spacer * 1.6);
$form-label-font-size:                  1.4rem;
$form-label-font-style:                 normal;
$form-label-font-weight:                $font-weight-bold;
$form-label-color:                      $gray-800;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y:                       5px;
$input-padding-x:                       10px;
$input-font-family:                     $font-family-base;
$input-font-size:                       1.1rem;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     1;

$input-bg:                              $white;
$input-disabled-color:                  $blue-200;
$input-disabled-bg:                     $input-bg;
$input-disabled-border-color:           $gray-400;

$input-color:                           $gray-450;
$input-border-color:                    $gray-400;
$input-border-width:                    $border-width;
$input-box-shadow:                      0 0 0 rgba(0, 0, 0, 0);

$input-border-radius:                   $border-radius-sm;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $input-border-color;
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-border-width;
$input-focus-box-shadow:                $input-box-shadow;

$input-placeholder-color:               $input-color;

// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width:                  16px;
$form-check-min-height:                   16px;
$form-check-padding-start:                24px;
$form-check-margin-bottom:                0;
$form-check-label-color:                  $gray-800;

$form-check-input-active-filter:          brightness(90%) !default;

$form-check-input-bg:                     $input-bg;
$form-check-input-border:                 1px solid $gray-300;
$form-check-input-border-radius:          4px;
$form-check-input-focus-border:           $input-focus-border-color;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow;

$form-check-input-checked-color:          $white;
$form-check-input-checked-bg-color:       $primary;
$form-check-input-checked-border-color:   $primary;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><path d='M9.19402 2.00641C9.13592 1.94783 9.0668 1.90133 8.99063 1.8696C8.91447 1.83787 8.83278 1.82153 8.75027 1.82153C8.66777 1.82153 8.58608 1.83787 8.50991 1.8696C8.43375 1.90133 8.36463 1.94783 8.30652 2.00641L3.65027 6.66891L1.69402 4.70641C1.6337 4.64813 1.56248 4.60231 1.48445 4.57156C1.40642 4.54081 1.32309 4.52573 1.23923 4.52718C1.15536 4.52863 1.07261 4.54658 0.995682 4.58002C0.918758 4.61345 0.849173 4.66171 0.790899 4.72203C0.732625 4.78236 0.686803 4.85357 0.656051 4.93161C0.625298 5.00964 0.610217 5.09297 0.611668 5.17683C0.613119 5.26069 0.631074 5.34345 0.664507 5.42037C0.69794 5.4973 0.746198 5.56688 0.806524 5.62516L3.20652 8.02516C3.26463 8.08374 3.33375 8.13023 3.40991 8.16196C3.48608 8.19369 3.56777 8.21003 3.65027 8.21003C3.73278 8.21003 3.81447 8.19369 3.89063 8.16196C3.9668 8.13023 4.03592 8.08374 4.09402 8.02516L9.19402 2.92516C9.25746 2.86663 9.30809 2.7956 9.34272 2.71654C9.37735 2.63747 9.39523 2.5521 9.39523 2.46578C9.39523 2.37947 9.37735 2.29409 9.34272 2.21503C9.30809 2.13597 9.25746 2.06493 9.19402 2.00641Z' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-disabled-opacity:        .5;
$form-check-label-disabled-opacity:        $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity:    $form-check-label-disabled-opacity;

// scss-docs-end form-check-variables

// scss-docs-start form-select-variables
$form-select-padding-y:             $input-padding-y;
$form-select-padding-x:             $input-padding-x;
$form-select-font-family:           $input-font-family;
$form-select-font-size:             $input-font-size;
$form-select-indicator-padding:     23px;
$form-select-font-weight:           $input-font-weight;
$form-select-line-height:           $input-line-height;
$form-select-color:                 $input-color;
$form-select-bg:                    $input-bg;
$form-select-disabled-color:        $input-disabled-color;
$form-select-disabled-bg:           $input-disabled-bg;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position:           right $form-select-padding-x center;
$form-select-bg-size:               7px 4px; 
$form-select-indicator-color:       $gray-800;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 4'><path d='M0.583252 0.646484L3.29159 3.35482L5.99992 0.646484' fill='#{$form-select-indicator-color}'/><path d='M0.583252 0.646484L3.29159 3.35482L5.99992 0.646484H0.583252Z' stroke='#{$form-select-indicator-color}' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/></svg>");

$form-select-border-width:        $input-border-width;
$form-select-border-color:        $input-border-color;
$form-select-border-radius:       $input-border-radius;
$form-select-box-shadow:          $input-box-shadow;

$form-select-focus-border-color:  $input-focus-border-color;
$form-select-focus-width:         $input-focus-width;
$form-select-focus-box-shadow:    $form-select-box-shadow;

// scss-docs-end form-select-variables

// scss-docs-start form-file-variables
$form-file-button-color:          $body-color;
$form-file-button-bg:             $gray-250;
$form-file-button-hover-bg:       shade-color($form-file-button-bg, 5%);
// scss-docs-end form-file-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top:          10px;
$form-feedback-font-size:           15px;
$form-feedback-font-style:          normal;
$form-feedback-valid-color:         $success;
$form-feedback-invalid-color:       $danger;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          none;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        none;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
) !default;
// scss-docs-end form-validation-states

// Toasts

// scss-docs-start toast-variables
$toast-max-width:                   70rem;
$toast-padding-x:                   1.667rem;
$toast-padding-y:                   1.667rem;
$toast-font-size:                   1.333rem;
$toast-color:                       $body-color;
$toast-background-color:            rgba($white, .85);
$toast-border-width:                0;
$toast-border-color:                transparent;
$toast-border-radius:               0;
$toast-box-shadow:                  $box-shadow;
$toast-spacing:                     20px;

$toast-header-color:                $body-color;
$toast-header-background-color:     rgba($white, .85);
$toast-header-border-color:         rgba($black, .05);
// scss-docs-end toast-variables