.accordion-panel {
  border: 1px solid $blue-200;
  border-radius: $accordion-inner-border-radius;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: stretch;
  font-size: 1.2rem;
  padding: $accordion-padding-y 41px $accordion-padding-y $accordion-padding-x;
  color: $accordion-button-color;
  background-color: $accordion-button-bg;

  @include media-breakpoint-down(xl) {
    font-size: 1rem;
  }
}

.accordion-header {
  position: relative;
}

.accordion-button {
  position: absolute;
  top: 15px;
  right: 19px;
  padding: 0;
  background: none;
  border-radius: 0;
  border: 0;
  width: auto;

  &:not(.collapsed) {
    ~ .accordion-panel .category-item {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0s;
    }
  }

  &:after {
    margin: 0;
  }
}

.accordion-body {
  padding-bottom: 0;

  p {
    margin: 0;
  }
}

.flag-item {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  color: $gray-500;
  margin: 0 3.1% 0 0;
  width: 11.5%;
  flex-shrink: 0;
  padding: 8px 0;

  @include media-breakpoint-down(xl) {
    width: 8%;
  }

  .country {
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 600;
    padding: 0 0 5px;
    display: block;
    color: $gray-800;

    @include media-breakpoint-down(xl) {
      font-size: 1.2rem;
    }
  }

  .fi {
    margin: 0 10px 0 0;
    font-size: 15px;
    flex-shrink: 0;
  }

  .text {
    strong {
      color: $gray-800;
      font-size: 1.1rem;
    }
  }
}

.accordion {
  .accordion-collapse {
    transition: height 0.7s linear;
  }

  .collapsing {
    transition: height 0.7s linear;
  }
}
