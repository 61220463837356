.table-block {
  h2 {
    margin: 0 0 17px;
  }
}

.table-group-divider {
  border-top: 1px solid $border-color;
}

.table-responsive {
  position: relative;
  z-index: 0;
  border: 1px solid $border-color;
  border-radius: $border-radius-sm;
  width: 100%;

  //2-02
  &.has-drop-down {
    padding-bottom: 30px;

    @media screen and (min-width: 1280px) {
      overflow: visible;
      padding-bottom: 0;
    }
  }
}

.table-wrap {
  margin: 0 -20px 0;
  padding: 0 20px;
  background-color: $white;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: -2px;
    z-index: 23;
    width: 22px;
    height: 3px;
    background-color: $white;
    content: '';
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.table {
  margin: 0;

  th {
    border-bottom: 1px solid $border-color;
    text-transform: uppercase;
    color: $gray-450;
    padding-top: 16px;
    padding-bottom: 16px;

    &.solid {
      color: $gray-800;
      border-right: 1px solid $border-color;

      &:last-child {
        border-right: none;
      }
    }
  }

  sub {
    font-size: 0.9rem;
  }

  i {
    font-size: 11px;
  }

  th,
  td {
    font-size: 1.1rem;
    line-height: 1;
    font-weight: $font-weight-medium;

    &:first-child,
    &:last-child {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.actions-column {
      width: 103px;

      .btn-holder {
        a {
          color: rgba($white, 0.75);
          text-decoration: none;
  
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .name-link {
    color: $gray-800;

    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }

  .border-right {
    border-right: 1px solid $border-color;
  }

  .fa-times-circle {
    color: $gray-450;
  }

  .logo-img {
    max-width: none;
  }

  .crop-text {
    max-width: 100px;
    display: block;
    line-height: 1.1;
    color: $gray-800;

    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }

  &.nowrap-text {
    td,
    th {
      white-space: nowrap;
    }
  }

  &.combine-striped {
    td {
      &.border-right {
        & + td {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    tbody {
      &:hover {
        td {
          box-shadow: none;
          background-color: $table-hover-bg;
        }
      }
    }
  }

  &.complex-table {
    th,
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }

    tr {
      &.own-shop {
        td {
          background: rgba(90, 190, 136, 0.1);
        }
      }
    }

    .actions-column {
      .btn-holder {
        justify-content: flex-end;
      }
    }

    thead {
      background-color: $white;
    }
  }
}

.cell {
  border: 1px solid $table-border-color;
  border-width: 0 1px 1px;
  padding: 13px 5px;
  min-height: 39px;

  &.highlight {
    border-left-color: $gray-800;
    border-right-color: $gray-800;

    a {
      justify-content: space-around;
    }
  }
}

.table-price {
  td {
    padding-top: 0;
    padding-bottom: 0;
  }

  td,
  th {
    padding-left: 5px;
    padding-right: 5px;
    max-width: 162px;

    &:first-child,
    &:last-child {
      padding-left: 5px;
      padding-right: 5px;
    }

    &.logo-cell {
      padding-left: 14px;
      padding-right: 14px;
      min-width: 147px;
      max-width: 147px;
    }
  }

  th {
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: none;
    color: $gray-800;
    text-transform: none;

    .cell {
      border-width: 1px;
      border-radius: $border-radius-sm $border-radius-sm 0 0;
      padding: 12px 12px 15px;
      background-color: $pale;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.highlight {
        background-color: $gray-800;
        color: $white;

        p {
          color: $gray-300;
        }
      }
    }

    img {
      margin-bottom: 10px;
    }

    .title {
      font-weight: $font-weight-medium;
      padding: 0 0 3px;
    }

    p {
      color: $gray-450;
      margin: 0;
      width: 100%;
    }
  }

  tbody {
    tr {
      &:last-child {
        .cell {
          border-radius: 0 0 $border-radius-sm $border-radius-sm;

          &.highlight {
            border-bottom-color: $gray-800;
          }
        }
      }
    }
  }

  .cell {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -11px -3px;
      padding: 11px 3px;
      width: calc(100% + 6px);
      text-decoration: none;
      border-radius: $border-radius-sm;
      color: $gray-800;

      &:hover {
        background-color: rgba($primary, 0.15);
        text-decoration: none;

        .price-holder {
          opacity: 0.6;
        }
      }
    }

    &.highlight {
      border-left-color: $gray-800;
      border-right-color: $gray-800;

      a {
        justify-content: space-around;
      }
    }
  }

  .price-holder {
    @include transition(opacity);
  }

  .dot-holder {
    display: flex;
    align-items: center;
  }

  .num {
    margin: 0 0 0 6px;
    font-size: 1rem;
    font-weight: $font-weight-normal;
    color: $gray-450;
    width: 1.8rem;
  }

  .dot {
    width: 9px;
    height: 9px;
    border-radius: $rounded-circle;
  }

  .alt-color {
    color: $gray-450;
  }

  .img-flag {
    width: 12px;
  }
}

table.dataTable {
  width: 100% !important;

  th,
  td {
    padding-left: 5px;
    padding-right: 5px;

    &:first-child {
      padding-right: 10px;
    }

    &:last-child {
      padding-left: 10px;
    }
  }

  &.table-hover {
    > tbody {
      > tr {
        &:hover {
          > * {
            box-shadow: none;
            background-color: $table-hover-bg;
          }
        }

        &.odd {
          > td {
            box-shadow: none;
            background-color: $table-striped-bg;
          }

          &:hover {
            > * {
              box-shadow: none;
              background-color: $table-hover-bg;
            }
          }
        }
      }
    }
  }
}

div.dataTables_wrapper {
  .table-title {
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
    color: $gray-800;
  }
}

div.dataTables_wrapper,
.table-wrapper {
  .paging-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;

    &.top {
      position: sticky;
      z-index: 23;
      top: 63px;
      background-color: $white;

      &:before {
        position: absolute;
        top: 0;
        left: -20px;
        right: -20px;
        border-top: 1px solid $border-color;
        z-index: 999;
        content: '';
        display: none;
      }

      &:after {
        position: absolute;
        bottom: -1px;
        left: -20px;
        right: -20px;
        border-bottom: 1px solid $border-color;
        content: '';
      }

      div.dataTables_info {
        margin: 0 auto;
        padding-left: 140px;
      }
    }
  }

  div.dataTables_info,
  .table-info {
    font-size: 1.2rem;
    color: $gray-450;
    padding-top: 0;

    span {
      font-weight: $font-weight-medium;
      color: $gray-800;
    }

    p {
      margin: 0;
    }
  }
}

.table-wrapper {
  hr {
    position: sticky;
    top: 63px;
    left: 0;
    margin: 0;
    border-color: $border-color;
    z-index: 24;
    opacity: 1;
    margin: 0 -20px;
  }

  .paging-holder {
    &.top {
      padding: 15px 2px;
    }
  }

  .pagination {
    margin: 0;
  }
}

.RadarTable {
  td {
    &:first-child {
      text-align: center;
    }
  }
}

table.ProductTable {
  margin: 0 !important;
  width: 100%;

  a {
    color: $gray-800;
    text-decoration: underline;

    &:hover {
      color: $primary;
    }
  }

  th {
    z-index: 1050;

    .inner {
      padding: 16px 10px 16px 20px;
      background-color: $white;
    }

    &:first-child {
      left: 0;
      z-index: 2000;
      position: sticky;
    }

    &:last-child:not(.actions-column) {
      text-align: right;
    }
  }

  th,
  td {
    padding-left: 5px;
    padding-right: 5px;

    &:first-child {
      padding: 0;
    }

    &:last-child {
      padding-left: 10px;
      padding-right: 20px;
    }
  }

  .inner {
    padding: 13px 10px 13px 20px;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background-color: $border-color;
      content: '';
    }
  }

  sub {
    line-height: inherit;
  }

  thead {
    > tr {
      > th {
        &.sorting {
          .th-holder {
            position: relative;
            padding-right: 15px;
          }
        }
      }
    }
  }

  &.table-hover {
    > tbody {
      > tr {
        &:hover {
          > * {
            box-shadow: none;
            background-color: #eff2f5 !important;
            color: inherit;
          }
        }

        &:nth-child(odd) {
          &:not([class]) {
            > td {
              box-shadow: none;
              background-color: $table-striped-bg;
  
              &:first-child {
                background-color: #f7f9fb;
              }
            }

            &:hover {
              > * {
                box-shadow: none;
                background-color: $table-hover-bg;
              }
            }
          }
        }
      }
    }
  }

  td {
    vertical-align: top;
    line-height: 1.4;

    &:not(:first-child) {
      &:hover {
        background-color: rgba($gray-800, 0.07);
      }
    }

    &:first-child {
      position: sticky;
      left: 0;
      z-index: 1040;
    }

    // #4-01
    &:last-child {
      text-align: right;

      .popup-opener {
        margin: 3px 0 0 auto;
        // margin: 3px 0 0 18px;
      }

      &.last-center {
        text-align: center !important;

        .popup-opener {
          margin: 0 auto;
        }
      }
    }
  }

  > tbody {
    tr {
      &:nth-child(odd) {
        &.highlight {
          > td {
            background-color: $light-blue;
            box-shadow: none;

            &:first-child {
              background-color: #cef4fc;
            }
          }
        }
      }

      &:nth-child(even) {
        &.highlight {
          > td {
            background-color: rgba($light-blue, 0.7);
            box-shadow: none;

            &:first-child {
              background-color: #ddf8fd;
            }
          }

          &:hover {
            > td {
              background-color: $table-hover-bg;
            }
          }
        }
      }

      &:not([class]) {
        td {
          &:first-child {
            background-color: $white;
          }
        }
      }
    }
  }

  .competitors-list {
    width: 370px;

    > li {
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
    }

    // #2-05
    .visual {
      width: auto;
      max-width: 54px;
      padding: 0;
      margin: 0 14px 0 0;
      align-self: flex-start;
    }

    .btn-holder {
      margin: 0 0 0 14px;
      flex-direction: column;
      align-items: flex-end;

      a {
        color: rgba($white, 0.75);
        text-decoration: none;

        &:hover {
          color: $white;
        }
      }
    }

    .description {
      overflow: hidden;
    }

    .name {
      display: inline;

      a {
        color: $gray-800;

        &:hover {
          color: $primary;
          text-decoration: underline;
        }

        &.dots-active {
          display: block;
        }
      }

      &:has(a) {
        display: block;
      }

      &.dots-active {
        display: block;
      }
    }

    .desc-list {
      font-size: 1rem;
      color: $secondary;
      font-weight: $font-weight-normal;
    }
  }

  .th-holder {
    display: inline-block;
    vertical-align: top;
  }

  .btn-up,
  .btn-down {
    position: absolute;
    right: 0;
    top: 50%;
    background: none;
    border: none;
    padding: 0;
    color: $gray-800;
    height: 10px;
    text-decoration: none;

    i {
      font-size: 12px;
      line-height: 10px;
      vertical-align: top;
    }

    &.disabled {
      color: $gray-300;
    }
  }

  .btn-up {
    transform: translateY(-9px);
  }

  .btn-down {
    transform: translateY(-3px);
  }

  .tag {
    min-width: 67px;
  }

  &.cell-link {
    td {
      > a {
        text-decoration: none;
        color: inherit;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

.filters-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $border-color;

  .clear-all {
    color: $secondary;
    font-size: 1.2rem;
    font-weight: $font-weight-medium;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.selected-filter {
  font-size: 1rem;
  color: $gray-800;
  padding: 4px 12px;
  border: 1px solid $gray-400;
  border-radius: 40px;
  background-color: $blue-200;
  margin: 0 8px 0 0;
  text-decoration: none;

  i {
    margin: 0 0 0 4px;
    color: $secondary;
    @include transition(color);
  }

  &:hover,
  &:active {
    background-color: $gray-400;
    color: $white;

    i {
      color: $white;
    }
  }
}

// #10-02
.sticky-thead {
  .thead-wrapper {
    position: fixed;
    z-index: 10000;
    overflow: hidden;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: $border-radius-sm $border-radius-sm 0 0;

    .table-head {
      display: flex;
      flex-direction: column;

      tr {
        padding: 0;
        display: flex;

        &:last-child {
          th {
            border-bottom: 0;
          }
        }
      }

      th {
        flex-shrink: 0;
        flex-grow: 0;
        .inner {
          margin: 0 1px 0 0;

          &:before {
            right: 1px;
          }
        }
      }
    }
  }

  .paging-holder.top {
    padding-bottom: 25px;

    &:after {
      bottom: 14px;
    }
  }
}

table {
  tr {
    &.total {
      td {
        --bs-table-accent-bg: $white !important;
        border-top: 1px solid $blue-200;
      }
    }
  }
}
