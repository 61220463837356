.filter-section {
    &.offcanvas.offcanvas-end {
        width: 100%;

        @include media-breakpoint-up(xl) {
            width: 1022px;
        }
    }

    .offcanvas-title {
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        text-transform: none;
    }

    .offcanvas-header {
        padding: 16px 24px 14px;
        border-bottom: 1px solid $border-color;
    }

    .offcanvas-footer {
        padding: 16px 24px;
        border-top: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        color: $gray-450;

        p {
            margin: 0;

            .num {
                font-weight: $font-weight-medium;
                color: $gray-800;
            }
        }
    }

    .offcanvas-body {
        padding: 16px 24px;
    }
}