.btn {
    i {
        margin: 0 3px;
    }

    .icon {
        height: 13px;
        width: auto;
    }

    &.btn-primary {
        --#{$prefix}btn-color: rgba(255, 2555, 255, 0.75);
        --#{$prefix}btn-hover-color: #{$white};

        &.show {
            color: $white;
            background-color: $primary;
        }
    }

    &.btn-secondary {
        --#{$prefix}btn-color: rgba(255, 2555, 255, 0.75);
        --#{$prefix}btn-hover-color: #{$white};
    }

    &.btn-light {
        border-color: rgba($gray-400, 0.5);
        --#{$prefix}btn-color: #{$gray-800};
        --#{$prefix}btn-hover-color: #{$gray-800};
        --bs-btn-active-bg: #{$gray-800};
        --bs-btn-active-color: rgba(255, 2555, 255, 0.5);
    }

    &.btn-gray-dark {
        --bs-btn-color: var(--bs-white);
        --bs-btn-hover-bg: #{$black};
        --bs-btn-active-bg: #{$gray-800};

        &.show {
            background-color: $gray-800;
        }
    }

    &.btn-darker {
        --bs-btn-color: rgba(255, 2555, 255, 0.75);
        --bs-btn-hover-bg: #{$gray-500};
        --bs-btn-hover-color: #{$white};
    }

    &.btn-white {
        --bs-btn-color: #{$gray-400};
        --bs-btn-bg: #{$white};
        --bs-btn-border-color: #{$blue-200};
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-border-color: var(--bs-btn-border-color);
        --bs-btn-hover-bg: #{$gray-400};
        --bs-btn-active-bg: #{$gray-400};
        --bs-btn-active-color: var(--bs-btn-hover-color);
    }

    &.btn-success {
        --#{$prefix}btn-color: rgba(255, 2555, 255, 0.6);
        --#{$prefix}btn-hover-color: #{$white};
    }

    &.btn-link {
        padding: 0;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: $font-weight-medium;

        i {
            color: $gray-300;
            margin: 0 4px 0 0;
            font-size: 1rem;
            @include transition(color);
        }

        &:hover {
            i {
                color: $primary;
            }
        }
    }
}