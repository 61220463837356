.side-nav {
  padding: 0;
  background: none;

  .offcanvas.offcanvas-start {
    visibility: visible;
    transform: none;
    width: 48px;
    @include transition(width);

    &.showing,
    &.show:not(.hiding),
    &:hover {
      width: 242px;
      box-shadow: 10px 0px 30px rgba(61, 61, 61, 0.2);

      .link-item,
      .side-brand,
      .dropdown-menu .dropdown-item {
        transform: scaleX(1);
        opacity: 1;
        visibility: visible;
      }

      .dropdown-toggle:after {
        opacity: 1;
        visibility: visible;
      }

      .dropdown-menu {
        padding-left: 25px;
      }
    }
  }

  .offcanvas-header {
    background-color: $blue-900;
    height: 63px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    flex-shrink: 0;

    .navbar-toggler {
      font-size: 11px;
      padding: 7px;
      pointer-events: none;
    }
  }

  .offcanvas-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  // #6-02
  .side-brand {
    position: relative;
    left: 8px;
    transform: scaleX(0);
    opacity: 0;
    visibility: hidden;
    transform-origin: 0 50%;
    @include transition(transform opacity visibility);
    overflow: hidden;

    img {
      max-width: 150px;
      max-height: 30px;
    }
  }

  .label-txt {
    background-color: $white;
    padding: 4px 6px;
    color: $gray-800;
    border-radius: 16px;
    font-size: 10px;
    line-height: 1;
    margin: 0 8px 0 0;
  }

  .nav-item {
    margin: 0;
    padding: 0 8px;
    position: relative;

    .navbar-toggler,
    .menu-icon {
      width: 32px;
      position: absolute;
      top: 4px;
      left: 0;
      height: 32px;
      z-index: 2;
      font-size: 12px;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }

    &.dropdown {
      overflow: hidden;
    }

    &.active,
    &:hover {
      .item-holder {
        background-color: $blue-700;

        > .nav-link,
        > .navbar-toggler {
          color: $white;

          &:after {
            color: $white;
          }
        }
      }
    }

    &.has-lines {
      margin: 16px 0 0;
      padding-top: 14px;

      &:before {
        position: absolute;
        left: 8px;
        top: 0;
        right: 8px;
        height: 1px;
        background-color: rgba($white, 0.12);
        content: '';
        z-index: 2;
      }
    }
  }

  .nav-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0 8px 32px;
    position: relative;
    min-height: 40px;
    font-size: 14px;
    line-height: 1;
    z-index: 2;

    &:focus,
    &:active {
      color: var(--bs-nav-link-color);
    }
  }

  .link-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transform: scaleX(0);
    padding: 0 0 0 4px;
    opacity: 0;
    visibility: hidden;
    transform-origin: 0 50%;
    @include transition(transform opacity visibility);
    overflow: hidden;
  }

  .dropdown-menu {
    position: relative;
    top: auto;
    left: 0;
    width: 100%;
    margin: -4px 0 0;
    // left: -32px;
    // margin: 0 -5px 0 0;
    padding: 0 0 8px;
    // width: calc(100% + 37px);
    border-radius: 0;
    border: none;
    z-index: 1;
    min-width: 1px;
    @include transition(padding);

    &:before {
      position: absolute;
      top: -99px;
      left: -99px;
      width: 999px;
      height: 999px;
      background-color: $dropdown-dark-bg;
      content: '';
      z-index: -1;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 0 5px 32px;
      border-radius: $border-radius-md;
      position: relative;
      min-height: 32px;
      margin: 0 0 3px;
      @include transition(background-color);

      &.active,
      &:hover {
        background-color: $blue-700;

        .navbar-toggler,
        .dropdown-item {
          color: $white;
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    .dropdown-item {
      border: none;
      padding: 0;
      font-size: 14px;
      line-height: 1.1;
      white-space: nowrap;
      transform: scaleX(0);
      opacity: 0;
      padding: 0 0 0 4px;
      visibility: hidden;
      transform-origin: 0 50%;
      @include transition(transform opacity visibility);
      overflow: hidden;
      font-weight: $font-weight-medium;

      &:hover,
      &:focus,
      &:active {
        background: none;
      }
    }

    .navbar-toggler {
      top: 0;
    }
  }

  .dropdown-toggle {
    &:after {
      transform-origin: 50% 50%;
      margin: 0 8px 0 0;
      color: $dropdown-dark-color;
      opacity: 0;
      visibility: hidden;
      @include transition(transform opacity visibility);
    }

    &.show {
      color: $white;

      &:after {
        transform: rotate(180deg);
        color: $white;
      }

      // + .navbar-toggler {
      //     color: $white;
      // }
    }
  }

  .item-holder {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include transition(background-color);
    border-radius: $border-radius-md;
  }
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.18);
}
