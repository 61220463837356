@import url('//fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
//@import "~bootstrap/scss/bootstrap";
@import 'style';
//@import '~select2/dist/css/select2.min';
@import url('//cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css');
@import '~ion-rangeslider/css/ion.rangeSlider.min';

@import '~trix/dist/trix';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

$flag-icons-path: '~flag-icons/flags';
@import '~flag-icons/sass/flag-icons.scss';
