.badge {
  min-width: 16px;
  height: 16px;
  background-color: $red-alt;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-decoration: none;

  &.large {
    padding-left: 8px;
    padding-right: 8px;
    height: 22px;
    min-width: 23px;
    border-radius: $border-radius-sm;
  }

  &:hover {
    text-decoration: none;
    color: $white;
  }
}
