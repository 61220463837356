.add-button {
    .btn {
        min-width: 150px;
        text-align: left;

        &:after {
            display: none;
        }

        &.show {
            .txt-default {
                display: none;
            }
        }

        &:not(.show) {
            .txt-open {
                display: none;
            }
        }
    }

    .dropdown-menu {
        color: $white;
        box-shadow: none;

        a {
            color: $white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            opacity: 0.5;

            i {
                margin-left: 10px;
            }

            &.active,
            &.add-item,
            &:hover {
                opacity: 1;
            }

            &.add-item {
                justify-content: flex-start;
            }
        }
    }

    .dropdown-divider {
        border-color: $white;
    }

    // &.full-width {
    //     .dropdown-menu {
    //         white-space: normal;
    //         left: 0 !important;
    //     }
    // }
}

.dropdown-divider {
    margin-top: 4px;
}

.dropdown-header {
    border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;
    background-color: $gray-800;
    margin: -9px -16px 8px;
    font-size: 13px;
}

.dropdown-menu {
    font-size: 1.1rem;

    a {
        @include transition(color background-color opacity);
        opacity: 0.5;

        i {
            margin: 0 3px 0 0;
        }

        &:hover {
            opacity: 1;
        }
    }

    h5 {
        font-size: 1.1rem;
        margin: 0;
        white-space: nowrap;
        padding: 5px 0 2px;
        text-transform: none;
    }

    &.bg-gray-dark {
        a {
            color: $white;
        }
    }

    .full-width & {
        min-width: 100%;
        right: 0 !important;
        left: auto !important;
        border-radius: 0 0 $btn-border-radius $btn-border-radius;
        margin: 26px 0 0 !important;
        transform: none !important;
        border: none;
        white-space: nowrap;
    }
}

.dropdown-toggle {
    &.alt {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            margin: 0 3px;
        }

        &:after {
            display: none;
        }

        // &.show {
        //     min-width: 117px;
        // }
    }
    
    &.show {
        .full-width & {
            border-radius: $btn-border-radius $btn-border-radius 0 0;
        }
    }
}

.dropdown-item {
    &.pin {
        i {
            transform: rotate(45deg);
            color: $red;
        }
    }
}

.user-block {
    .lang-dropdown {
        .dropdown-toggle {
            min-width: 126px;
            border: 1px solid $blue-200;
            border-radius: $border-radius-sm;
            background-color: $white;
            height: 32px;
            padding: 5px 20px 5px 14px;
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            color: $gray-800;
            font-weight: $font-weight-medium;
            line-height: 1;
    
            i {
                margin: 0 7px 0 0;
                font-size: 1rem;
            }
    
            &:after {
                color: $gray-800;
                vertical-align: 0;
                position: absolute;
                right: 11px;
                top: 50%;
                transform: translateY(-50%);
            }
    
            &.show {
                border-color: rgb(153, 153, 153) rgb(153, 153, 153) transparent;
                border-radius: 3px 3px 0 0;
                background: $white;
            }
        }
    
        .dropdown-menu {
            top: calc(100% - 1px) !important;
            right: auto;
            margin: 0 !important;
            border-width: 0 1px 1px;
            border-style: solid;
            border-color: transparent #999 #999;
            border-radius: 0 0 3px 3px;
            background-color: $white;
            width: 100%;
            padding: 5px 0;
            box-shadow: none;
    
            li {
                &.active {
                    a {
                        background-color: $gray-200;
                        color: $gray-800;
                    }
                }
            }
    
            a {
                padding: 8px 14px;
                font-size: 1.2rem;
                color: $gray-800;
                font-weight: normal;
                line-height: 1;
                display: flex;
                align-items: center;
                @include transition(color background-color);
    
                i {
                    margin: 0 7px 0 0;
                    font-size: 1rem;
                    border-radius: 0;
                }
    
                &:hover {
                    background-color: $gray-200;
                    color: $gray-800;
                }
            }
        }
    }
}