
// -----------------------------------------------------------------------------
// This file contains typography styles.
// -----------------------------------------------------------------------------

///*
//  Set up a decent box model on the root element
//*/

// set html { font-size: 62.5%; } if you want to make 1rem equal to 10px. dot not use it with css frameworks like bootstrap. tailwind etc.

/*
  Basic typography style for copy text
*/
body {
  color: $body-color;
  font-family: $font-family-base;
  font-size: map-get($font-sizes, 'base');
  line-height: $line-height-base;
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(lg) {
    min-width: 1024px;
  }

  &.home {
    @include media-breakpoint-up(lg) {
      overflow-y: scroll;
    }
  }
}

/*
  Basic styles for links
*/
a {
  @include transition(color background-color);
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

ul,
ol,
dl,
p {
  margin: 0 0 $paragraph-margin-bottom;
}

h1,
.h1 {
  font-weight: $font-weight-bold;
}

h5,
.h5,
h6,
.h6 {
  text-transform: uppercase;
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: bold; 
}