[data-name] {
  width: 330px;

  .table {
    th,
    td {
      font-size: 1rem;
      padding: 10px 5px;
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .flag-holder {
      font-size: 1rem;

      .fi {
        font-size: 12px;
      }
    }

    .tag {
      min-width: 40px;
    }
  }
}

[data-name='popover-content wide'] {
  width: 510px;
  margin: 0 -16px;
  display: flex;

  .left-col {
    width: 40%;
    flex-shrink: 0;
    align-self: center;
  }

  .right-col {
    flex-grow: 1;
    border-left: 1px solid $blue-200;
    padding: 0 16px;
  }

  .table {
    margin: 0 0 10px;

    tbody {
      tr {
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.popup-opener {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  width: 16px;
  height: 16px;
  color: $secondary;
  text-decoration: none;
  margin: 3px auto 0;

  svg {
    display: block;
    width: 100%;
    height: 100%;

    path {
      @include transition(stroke fill);
    }
  }

  &:hover {
    color: $white;
    text-decoration: none;

    .path {
      stroke: $primary;
      fill: $primary;
    }
  }

  &[aria-describedby] {
    color: $white;

    .path {
      stroke: $primary;
      fill: $primary;
    }
  }
}

// #4-02
[data-name='popover-content'] {
  .popup-footer {
    padding: 1rem 0;
    > :last-child {
      margin-bottom: 0;
    }
  }
}
