.category-item {
  max-width: 260px;
  margin: 0 6% 0 0;
  display: flex;
  flex: 1;
  transition: opacity 0.5s ease-in-out 0.3s, visibility 0.5s ease-in-out 0.3s;

  &:last-child {
    margin: 0;
  }

  .category-statistic {
    width: 50%;
    background-color: $gray-200;
    border-radius: $border-radius-sm;
    padding: 4px 8px 1px 7px;
    min-height: 54px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: $gray-500;
    position: relative;
  }

  .statistic-holder {
    padding: 3px 0 0;
  }

  .badge {
    margin: 0 0 0 5px;
    position: absolute;
    top: 4px;
    right: 8px;
  }

  .category-name {
    font-weight: $font-weight-medium;
    display: block;
    color: $gray-800; //7-01
    padding-right: 21px;
  }

  .text {
    font-size: 1rem;
    display: block;
    padding: 0 0 2px;
  }

  .percent-info {
    display: flex;
    align-items: baseline;

    strong {
      font-size: 1.6rem;
      color: $gray-800;
      margin: 0 3px 0 0;
    }

    .small {
      color: $red;
      font-size: 1rem;
    }
  }

  &.empty {
    //7-01
    .category-statistic {
      //   color: rgba($gray-500, 0.7);

      .category-name {
        color: $gray-500;
      }
    }

    .category-info {
      color: $gray-300;
    }
  }
}

.category-info {
  padding: 0 0 0 17px;
  font-size: 1rem;
  color: $gray-600;
  width: 50%;
  align-self: center;

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 0 2px;
  }

  //7-01
  .number {
    padding-left: 4px;
    white-space: nowrap;
    // width: 20px;
  }
}
