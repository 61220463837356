// -----------------------------------------------------------------------------
// This file contains all styles related to the common layout of the site
// -----------------------------------------------------------------------------

body {
  background-color: $body-bg;
}

.wrapper {
  width: 100%;
  position: relative;
  // overflow: hidden;
  display: flex;
  min-height: 100vh;
}

// skip links
.accessibility {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
}

.page-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 49px;
}

.main {
  padding-bottom: 84px;
}

.main-holder {
  padding: 21px 22px 78px 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.middle-block {
    justify-content: center;
    align-items: center;
    padding-bottom: 21px;

    .main {
      padding-bottom: 0;
    }
  }
}

.home-block {
  padding-top: 8px;
}

sub,
.sub {
  bottom: 0;
  font-size: 1rem;
  position: relative;
}

.tag {
  color: $white;
  border-radius: $border-radius-sm;
  padding: 4px 5px;
  font-size: 1rem;
  font-weight: $font-weight-medium;
  text-align: center;
  min-width: 57px;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;

  &.lighter {
    background-color: $white;
    border: 1px solid $border-color;
    color: $gray-800;

    .highlight & {
      border-color: $blue-400;
    }
  }
}

a.tag {
  &:hover {
    color: $white;
  }
}

.info-sign {
  font-family: $font-family-secondary;
  font-size: 1.2rem;
  font-weight: bold;
  color: $gray-300;
  vertical-align: baseline;
  margin: 0 0 0 3px;
  text-decoration: none;
  font-style: italic;
  padding: 0 5px;
}

.box-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px;

  h2 {
    font-size: 1.7rem;
    margin: 0;
  }

  .h4 {
    margin: 0;
  }
}

.data-list {
  margin: 0;
  padding: 0 0 1px;

  li {
    border-bottom: 1px solid $blue-300;
    padding: 0 0 4px;
    margin: 0 0 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .data-value {
    width: 40px;
    color: $gray-800;
    display: flex;
    align-items: baseline;
  }

  .sub {
    font-size: 1rem;
    color: $gray-400;
    margin: 0 0 0 3px;
  }
}

.progress-block {
  padding: 1px 0 18px;
  margin: 0 0 20px;
  border-bottom: 1px solid $border-color;

  .number-holder {
    font-size: 1.1rem;
    color: $gray-800;
    display: flex;
    align-items: center;

    .num {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0 0 0 5px;
      color: $gray-500;
    }

    a {
      color: $red;
    }

    .sub {
      color: $gray-400;
      margin: 0 0 0 2px;
    }
  }
}

.color-description {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;

  dt {
    width: 9px;
    height: 5px;
    margin: 0 4px 0 0;
  }

  dd {
    font-size: 1rem;
    line-height: 1;
    margin: 0 10px 0 0;
  }

  &.circle {
    padding-bottom: 22px;

    dt {
      width: 9px;
      height: 9px;
      border-radius: $rounded-circle;
      margin: 0 6px 0 0;
    }

    dd {
      font-size: 1.1rem;
      color: $gray-800;
      font-weight: $font-weight-medium;
      margin: 0 25px 0 0;
    }
  }
}

.discount-block {
  display: flex;

  .description {
    padding: 0 14px 0 0;
    margin: 0 20px 0 0;
    font-size: 1rem;
    position: relative;

    h4 {
      margin: 0 0 5px;
    }

    p {
      margin: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background-color: $gray-250;
      content: '';
    }
  }

  .discount-column {
    width: 74px;
    margin: -2px 0 0;
  }
}

.discount-column {
  h4 {
    font-weight: $font-weight-bold;
    margin: 0 0 3px;
    text-transform: none;
  }
}

.discount-list {
  font-size: 1rem;
  color: $gray-650;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.service-progress-block {
  padding: 17px 0 14px;
  border-top: 1px solid $border-color;

  > h4 {
    margin: 0 0 7px;
    font-weight: $font-weight-bold;

    .sub,
    sub {
      font-size: 1rem;
    }
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  //1-04
  .data-col {
    max-width: 180px;
    // max-width: 125px;
    flex-grow: 1;
  }

  .discount-column {
    width: 20%;

    h4 {
      @include media-breakpoint-down(xl) {
        margin-right: -12px;
      }
    }
  }

  //1-04
  .multi-progress {
    width: 46%;
    padding: 2px 6px 0 6px;

    @media screen and (min-width: 1360px) {
      width: 53%;
    }
  }

  &:first-child {
    border: none;
    padding-top: 3px;
  }
}

.service-data {
  margin-bottom: 4px;
  background-color: $blue-100;
  border-radius: $border-radius-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 5px 6px;

  .name {
    font-size: 1rem;
    color: $gray-800;
    padding: 0 10px 0 0;

    @include media-breakpoint-up(xl) {
      font-size: 1.1rem;
    }
  }

  .value {
    width: 5rem;
    // width: 4rem;
    font-size: 1.3rem;
    font-weight: $font-weight-medium;
    color: $primary;

    .sub {
      color: $gray-500;
      font-weight: $font-weight-normal;

      a {
        color: $gray-500;
      }
    }
  }
}

.message-icon {
  font-size: 1.6rem;
  color: $gray-400;
  background: none;
  border: none;
  padding: 0;
  cursor: default !important;

  .badge {
    position: absolute;
    top: -50%;
    left: 100%;
    margin: 3px 0 0 -5px;
  }

  &[data-bs-toggle='modal'] {
    cursor: pointer !important;

    &:hover {
      color: $primary;
    }
  }
}

.chart-block {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  .img-holder {
    // width: 86px; //1-02
  }

  img {
    margin-bottom: 5px;
    display: block;
    width: 100%;
  }

  .caption {
    font-size: 1rem;
  }

  .message-icon {
    position: absolute;
    top: 0;
    right: -4px;
  }
}

///////////////////
// 2-11
.definition-list {
  margin: 0;

  li {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    border-top: 1px solid $blue-100;
    padding: 6px 0 7px;

    &:first-child {
      border: none;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .name {
    color: $gray-800;
    flex: 0 1 40%;
  }

  .num {
    text-align: right;
    font-size: 1.1rem;
    color: $gray-500;
    margin-left: 0.5rem;
    flex: 0 1 60%;
  }

  .text-white & {
    .name {
      color: $white;
    }

    .num {
      color: $blue-200;
    }

    li {
      border-color: rgba($pale, 0.1);
    }
  }
}
///////////////////

.property-item {
  font-size: 1.4rem;
  font-weight: $font-weight-bold;
  color: $gray-800;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 1.2rem;
  margin-bottom: 2px;
  width: 100%;

  @include media-breakpoint-down(xl) {
    font-size: 1.2rem;
  }

  .price {
    font-weight: $font-weight-medium;
    color: $gray-500;
  }

  &:first-child {
    border-radius: $border-radius-sm $border-radius-sm 0 0;
  }

  &:last-child {
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
    margin-bottom: 0;
  }

  .chip-item & {
    border-radius: 0;
    padding: 0;
    margin: 0;
  }
}

.chip-item {
  padding-top: 9px;
  padding-bottom: 6px;
}

.competitors-list {
  margin: -4px 0 0;

  li {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
  }

  // 2-04
  .visual {
    width: 27%;
    flex-shrink: 0;
    border-radius: 4px;
    margin-right: 16px;
    overflow: hidden;
    border: 1px solid $blue-200;

    img {
      width: 100%;
    }
  }

  // .visual {
  //   width: 34%;
  //   padding: 0 16px 0 0;
  //   flex-shrink: 0;

  //   img {
  //     display: block;
  //     width: 100%;
  //     margin: 0;
  //   }
  // }

  .description {
    flex-grow: 1;
    font-size: 1.1rem;
    color: $gray-800;

    @include media-breakpoint-down(xl) {
      font-size: 1rem;
    }
  }

  .name {
    font-weight: $font-weight-medium;
    display: block;
    padding: 0 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      text-decoration: underline;
      color: $gray-800;

      &:hover {
        color: $primary;
      }
    }
  }

  a {
    font-weight: $font-weight-medium;
  }

  .desc-list {
    margin: 0;

    li {
      padding: 0 0 2px;

      > a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .val {
      color: $gray-500;
      padding: 0 0 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.tabs-bar {
  .card-body {
    padding-top: 16px;
    padding-bottom: 17px;
  }
}

.graphic-block {
  padding-bottom: 45px;

  h3 {
    margin: 0 0 14px;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.graphic-box {
  border: 1px solid $border-color;
  border-radius: $border-radius-sm;
  padding: 22px 16px;

  .graphic-holder {
    margin-bottom: 5px;

    img {
      display: block;
      width: 100%;
    }
  }
}

.btn-holder {
  display: flex;

  .btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 14px;

    &.btn-sm {
      width: 22px;
      height: 22px;
      font-size: 12px;
      margin: 0 6px 0 0;
      padding: 0;
    }

    &.dropdown-toggle {
      &:before {
        display: none;
      }
    }
  }

  .dropdown-menu {
    min-width: 10px;
    white-space: nowrap;
    top: 50% !important;
    right: 0 !important;
    bottom: auto !important;
    left: auto !important;
    position: absolute !important;
    transform: translate(-42px, -50%) !important;

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid $gray-800;
      content: '';
    }

    a {
      color: $white;
      text-decoration: none;
      opacity: 0.5;

      &:hover {
        opacity: 1;
        color: $white;
      }
    }
  }
}

.product-block {
  padding-bottom: 30px;

  &:last-child {
    padding-bottom: 0;
  }
}

.sidebar {
  width: 23.6%;
  max-width: 292px;
  flex: none;
  will-change: min-height;

  .btn {
    padding-left: 6px;
    padding-right: 6px;
  }

  .competitors-list {
    .description {
      flex-grow: 0;
      width: 70%;
    }
  }
}

.sidebar__inner{
  transform: translate(0, 0); /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;

  // .is-affixed & {
  //   padding: 0 8px;
  // }
}

.content-wrapper {
  min-width: 76.4%;
  max-width: calc(100% - 292px);
  flex: none;
}

.rate-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1.4rem;
  color: $secondary;

  .num {
    font-size: 2.4rem;
    font-weight: $font-weight-medium;
    color: $gray-800;
    padding: 0 0 10px;
  }

  p {
    margin: 0;
    text-align: center;
  }
}

.product-card {
  .card-opener {
    font-size: 1.6rem;
    line-height: 1.35;
  }

  .card-body {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .rate-block {
    &.card-body {
      padding-top: 16px;
      padding-bottom: 14px;
    }

    .card-body {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
}

.star-list {
  margin: 0 0 15px;
  display: flex;
  justify-content: center;
  transform: scale(0.5);

  @include media-breakpoint-up(xl) {
    transform: none;
  }

  li {
    padding: 0 2px;
  }

  .icon-star {
    position: relative;
    width: 30px;
    height: 28px;
    display: block;
    background: url('../images/bg-star-pale.svg') no-repeat;
  }

  .fill-star {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg-star.svg') no-repeat;
  }
}

.rate-block {
  .multi-progress {
    width: 100%;

    .title {
      width: auto;
      font-size: 1.4rem;
      color: $gray-800;
      padding: 0 8px 0 0;

      i {
        font-size: 1rem;
        vertical-align: middle;
        display: inline-block;
      }
    }

    .value {
      width: 3.4rem;
      font-size: 1.4rem;
      color: $secondary;
    }

    .progress-row {
      padding: 7px 0 0;
      margin-bottom: 7px;
      border-top: 1px solid $pale;

      &:first-child {
        padding-top: 0;
        border: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.product-item {
  display: flex;
  align-items: center;

  // 2-06
  .visual {
    width: 19.2%;
    flex-shrink: 0;
    margin-right: 16px;
    border: 1px solid $blue-200;
    border-radius: 4px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  // .visual {
  //   width: 25.7%;
  //   flex-shrink: 0;
  //   padding: 0 16px 0 0;
  // }

  .text-white & {
    * {
      color: $white;
    }
  }
}

.subtitle {
  display: block;
  padding-bottom: 21px;
  font-size: 1.6rem;
  font-weight: $font-weight-medium;
  color: $gray-800;

  i {
    color: $gray-300;
    font-size: 1.4rem;
    margin: 0 2px 0 0;
    @include transition(color);
  }
}

.sticky-card {
  border-radius: 0 0 $card-border-radius $card-border-radius;
  margin: -1px 0 0;
  position: relative;

  .nav-tabset {
    margin: 0;

    .nav-link {
      font-size: 1.1rem;
      font-weight: $font-weight-medium;
      padding: 0 0 14px;
      margin-top: 16px;
      display: flex;
      align-items: center;

      span {
        color: $darker;
        margin: 0 0 0 3px;
        @include transition(color);
      }

      .fi {
        font-size: 8px;
        margin: 0 5px 0 0;
      }

      &.active,
      &:hover {
        span {
          color: $secondary;
        }
      }
    }
  }

  &:before,
  &:after {
    position: absolute;
    bottom: 100%;
    margin: 0 0 1px;
    height: 10px;
    width: 20px;
    background-color: $white;
    content: '';
  }

  &:before {
    left: -1px;
    border-left: 1px solid $border-color;
  }

  &:after {
    right: -1px;
    border-right: 1px solid $border-color;
  }

  &.extra-top {
    border-top: none;
    margin: 0;

    &:before,
    &:after {
      margin: 0;
    }
  }
}

.sub-holder {
  padding-left: 20px;
  padding-right: 20px;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  background-color: $white;
}

.compare-products {
  font-size: 1.6rem;
  color: $gray-800;
  display: block;
  padding: 0 0 20px;
  font-weight: $font-weight-medium;

  strong {
    color: $gray-450;
    font-weight: $font-weight-semibold;
  }
}

.distributors-heading {
  padding-top: 31px;
  padding-bottom: 11px;

  .flag-holder {
    font-size: 1.6rem;

    .img-flag {
      width: 16px;
      margin: 0 8px 0 0;
    }

    .fi {
      font-size: 12px;
      margin: 0 8px 0 0;
    }

    i {
      font-size: 1rem;
    }

    a:not([class]) {
      text-decoration: none;

      h2 {
        @include transition(color);
      }

      &:hover {
        h2 {
          color: $primary;
        }
      }
    }
  }

  .btn-primary {
    .icon {
      color: rgba($white, 0.75);
    }
  }
}

.view-group {
  .btn-white {
    font-size: 12px;
    min-width: 34px;
    padding-left: 5px;
    padding-right: 5px;

    &.active {
      color: var(--bs-btn-hover-color);
      background-color: var(--bs-btn-hover-bg);
    }
  }
}

.flag-holder {
  display: flex;
  align-items: center;
  color: $gray-800;
  font-weight: $font-weight-medium;
  font-size: 1.1rem;

  .img-flag {
    margin: 0 5px 0 0;
    width: 16px;
    flex-shrink: 0;
  }

  .fi {
    font-size: 12px;
    margin: 0 5px 0 0;
  }

  a {
    &:not([class]) {
      color: $gray-800;
      text-decoration: underline;

      &:hover {
        color: $primary;
      }
    }
  }

  .alt-link {
    margin: 0 5px 0 0;
  }
}

.progress-row {
  .progress {
    flex-grow: 1;
  }

  .flag-holder {
    width: 11.6rem;
    padding: 0 10px 0 0;
    align-items: flex-start;

    .img-flag {
      width: 10px;
      margin: 0 3px 0 0;
    }

    .fi {
      font-size: 8px;
      margin: 4px 3px 0 0;
      flex-shrink: 0;
    }

    i {
      font-size: 10px;
    }

    a:not([class]) {
      max-width: 80%;
    }
  }

  &.larger {
    .flag-holder {
      width: 17.7rem;
    }
  }
}

.switcher-label {
  font-size: 1.1rem;
  font-weight: $font-weight-medium;
  color: $secondary;
  margin: 0 8px 0 0;
  align-self: center;
}

.tooltip {
  &.larger {
    .tooltip-inner {
      max-width: 253px;
    }
  }
}

.table-popover {
  padding: 0;
  border: none;
  background: none;
  text-decoration: underline;
  color: $primary;

  &:hover {
    text-decoration: underline;
  }
}

.btn.btn-link.filters-opener {
  cursor: pointer;
  position: relative;
  z-index: 22;

  i {
    color: $gray-800;
  }
}

.fix-for-table {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $white;
    z-index: 25;
    content: '';
  }

  &:before {
    top: 4px;
  }

  &:after {
    top: 56px;
    display: none;
  }
}

.notfound-block {
  position: relative;
  font-size: 14px;
  color: $secondary;
  text-align: center;
  max-width: 530px;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 982px;
    height: 423px;
    background-image: url('../images/404.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    content: '';
    z-index: -1;
  }

  h1 {
    margin: 0 0 35px;
    font-size: 40px;
  }

  p {
    margin: 0 0 22px;

    &:last-of-type {
      margin: 0 0 35px;
    }
  }

  .btn {
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.login-page {
  width: 600px;

  .navbar-brand {
    margin: 0 auto 36px;
    display: block;
    width: 252px;
  }

  .card {
    margin-bottom: 37px;
  }

  h2 {
    font-size: 28px;
    margin: 0 0 31px;
    text-align: center;
  }
}

.login-footer {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: $secondary;

  .text-holder {
    margin: 0 auto;
    max-width: 411px;
    padding: 0 0 45px;
  }

  p {
    margin: 0;
  }

  ul {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -15px;

    li {
      padding: 0 15px;

      &.active {
        a {
          color: $primary;
        }
      }
    }

    a {
      color: $secondary;
      text-decoration: none;

      &:hover {
        color: $primary;
      }
    }
  }
}

.alert-list {
  padding: 20px 0 0;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-top: 1px solid $blue-200;

    &:first-child {
      border: none;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  a {
    font-size: 1.1rem;
    color: $gray-800;
    text-decoration: none;
    font-weight: $font-weight-medium;

    i {
      margin: 0 0 0 5px;
      color: $gray-450;
      @include transition(color);
    }

    &:hover {
      color: $primary;

      i {
        color: $primary;
      }
    }
  }

  .badge {
    min-width: 19px;
    border-radius: 4px;
  }
}

.fab-block {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;

  .dropdown-divider {
    margin-bottom: 9px;
    margin-top: 0;
  }

  .dropdown-menu {
    text-align: center;
    margin-bottom: 13px !important;
    right: -4px;
    left: auto;
    bottom: 0;
    padding-bottom: 13px;
    font-size: 1.2rem;

    .dropdown-header {
      text-transform: none;
    }

    p {
      margin: 0 0 10px;
    }

    a {
      opacity: 1;
      color: $gray-500;
      text-decoration: none;
      font-size: 1.2rem;

      &.email {
        font-size: 1.1rem;
        font-weight: $font-weight-normal;
      }

      &.contact-link {
        color: $gray-500;

        i {
          color: inherit;
          font-size: 1.5rem;
          margin: 0 9px 0 0;

          &.fa-phone-alt {
            color: $white;
            font-size: 0.7rem;
            margin: 0 12px 0 4px;
          }
        }

        &:hover {
          .fa-phone-alt {
            &:after {
              background-color: $primary;
            }
          }
        }
      }

      &:hover {
        color: $primary;
      }
    }

    img {
      display: inline-block;
      vertical-align: top;
      border-radius: 50%;
      margin: 0 0 7px;
      padding-top: 6px;
    }
  }
}

.fab-button {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: $primary;
  border: none;
  padding: 0;
  color: $white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;

  svg {
    display: none;
  }

  &.dropdown-toggle {
    &:after {
      display: none;
    }

    &.show {
      span {
        display: none;
      }

      svg {
        display: block;
      }
    }
  }
}

.contact-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $gray-500;
  margin-bottom: 10px;

  i {
    margin: 0 9px 0 0;

    &.fa-phone-alt {
      color: $white;
      font-size: 0.7rem;
      position: relative;
      margin: 0 12px 0 4px;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
        background-color: $gray-500;
        z-index: -1;
        content: '';
        @include transition(background-color);
      }
    }
  }
}

.back-top {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  font-size: 20px;
  color: #666;
  position: fixed;
  bottom: 88px;
  right: 15px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  @include transition(opacity visibility);

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.toolbar-page {
  .btn {
    &:active {
      color: $white;
    }
  }

  .btn-favorite {
    &.checked,
    &.active,
    &:active {
      color: $white;
      background-color: $primary;

      i {
        &:before {
          font-weight: 900;
        }
      }
    }
  }
}

.navbar-nav {
  &.secondary-nav {
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 0.5rem;

    .nav-link {
      font-size: 1.334rem;
      color: $gray-500;
      font-weight: normal;
      align-items: center;
      display: flex;

      i,
      .icon {
        margin-right: 0.5rem;
        font-size: 1rem;
        color: $gray-300;
        @include transition(color);
      }

      &:hover {
        color: $primary;

        i,
        .icon {
          color: $primary;
        }
      }
    }

    .nav-item {
      margin-right: 2.1rem;

      // #5-01
      &.active {
        > a {
          color: $gray-800;

          .fas {
            color: inherit;
          }

          &:after {
            color: inherit;
          }
        }
      }
    }

    .dropdown-toggle {
      &:after {
        color: $gray-300;
        vertical-align: 3px;
        margin-left: 8px;
      }

      &.show {
        color: $primary;

        i,
        .icon {
          color: $primary;
        }
      }
    }

    .dropdown-menu {
      font-size: 1.1rem;
      margin-top: 7px !important;
      left: -0.5rem !important;
      padding-top: 14px;

      li {
        padding: 0 0 7px;

        &.active {
          a {
            color: $gray-800;
          }
        }
      }

      a {
        opacity: 1;
        font-weight: $font-weight-medium;
        padding: 0;

        &:hover {
          color: $gray-800;
        }
      }
    }
  }
}

//2-01
div[role='toolbar'] {
  .dropdown {
    .dropdown-item {
      white-space: inherit;
    }
  }
}

//3-02
.heading-truncate {
  display: flex;
  align-items: center;

  strong {
    margin-left: 6px;
    margin-right: 6px;
  }
}
