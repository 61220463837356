.toast {
    font-size: 1.333333rem;
    position: relative;
    font-weight: $font-weight-medium;
    box-shadow: 0px -20px 50px 0px rgba(0, 0, 0, 0.20);

    .toast-body {
        i {
            margin: 0 12px 0 0;
        }
    }

    .btn-close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2px;
        background: none;
        color: rgba($white, 0.75);
        opacity: 1;
        filter:none;

        svg {
            path {
                @include transition(color);
            }
        }

        &:hover {
            color: $white;
            opacity: 1;
        }
    }
}