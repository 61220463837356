.message-holder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
    background-color: rgba($gray-800, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.mobile-message {
    font-size: 5vw;
    width: 80%;
    padding: 50px;
    text-align: center;
}