// stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors

// -----------------------------------------------------------------------------
// This file contains all styles related to the form elements of the site
// -----------------------------------------------------------------------------

input[type='text'],
input[type='email'],
input[type='tel'],
textarea {
  width: 100%;
  min-width: 0;
  -webkit-appearance: none;

  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
  }
}

.selectr-container {
  min-width: 126px;

  .selectr-input-container {
    display: none !important;
  }

  .my-template {
    display: flex;
    align-items: center;
  }

  .fi {
    margin: 0 7px 0 0;
    font-size: 12px;
  }
}

.selectr-options-container {
  padding: 5px 0;
}

.selectr-selected {
  border-color: $blue-200;
  border-radius: $border-radius-sm;
  height: 32px;
  padding: 5px 20px 5px 14px;
  display: flex;
  align-items: center;

  .selectr-label {
    font-size: 1.2rem;
    color: $gray-800;
    font-weight: $font-weight-medium;
    line-height: 1;
  }

  &:before {
    border-color: $gray-800 transparent transparent;
    right: 11px;
  }
}

.selectr-option {
    padding: 8px 14px;
    font-size: 1.2rem;
    color: $gray-800;
    line-height: 1;
    @include transition(color background-color);

    &.active,
    &.selected {
      background-color: $gray-200;
      color: $gray-800;
    }
}

.form-control {
  box-shadow: none;
}

.filter-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

  .form-row {
    display: flex;
    margin: 0 -18px;
  }

  .form-col {
    width: 33.3%;
    padding: 0 18px;

    .form-select,
    .form-control {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .amount-field {
    padding: 0;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    border: none;
  }

  .price-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    color: $gray-800;
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
  }
}

.ui-slider-horizontal {
  height: 2px;

  &.ui-widget.ui-widget-content {
    border: none;
    background-color: $border-color;
    margin: 0 4px 0 8px;
  }
}

.ui-widget-header {
  background-color: $gray-800;
}

.ui-slider-handle {
  &.ui-state-default {
    top: -7px;
    width: 16px;
    height: 16px;
    border-radius: $rounded-circle;
    border: 2px solid $gray-800;
    background-color: $white;
  }
}

.form-select,
.form-control {
  margin-bottom: 12px;
  height: 34px;
}

.select2-container {
  margin-bottom: 12px;
  display: block;
  width: 100% !important;

  .select2-selection--single,
  .select2-selection--multiple {
    padding: $form-select-padding-y $form-select-padding-x;
    font-family: $form-select-font-family;
    font-size: $form-select-font-size;
    line-height: $form-select-line-height;
    font-weight: $form-select-font-weight;
    color: $form-select-color;
    background-color: $form-select-bg;
    border: $form-select-border-width solid $form-select-border-color;
    border-radius: $form-select-border-radius;
    height: 34px;
    width: 100%;

    .select2-selection__rendered {
      line-height: 21px;
      color: $form-select-color;
      padding-left: 0;
    }

    .select2-selection__clear {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $form-select-padding-x;
    }

    .select2-search__field {
      padding: 4px 0 0;
      color: $form-select-color;
      margin: 0;
      line-height: 1;
      display: block;
      min-height: 21px;

      @include placeholder {
        opacity: 1;
        color: $form-select-color;
      }
    }

    .select2-selection__arrow {
      height: 100%;
      width: 28px;

      b {
        border-top-color: $form-select-indicator-color;
        border-top-width: 4px;
      }
    }

    .select2-selection__choice {
      margin: 0 0 5px;
      background-color: $blue-200;
      border-color: $blue-200;

      .select2-selection__choice__remove {
        border: none;
      }
    }
  }

  .select2-selection--single {
    .select2-selection__clear {
      display: none;
    }
  }

  .select2-selection--multiple {
    height: auto;
    min-height: 34px;

    .select2-selection__rendered {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }
  }
}

.select2-container--default {
  .select2-results {
    >.select2-results__options {
      font-size: $form-select-font-size;

      &:not([aria-multiselectable="true"]) {
        > li {
            color: $form-select-color;
            padding-left: $form-select-padding-x;
            padding-right: $form-select-padding-x;

            &.select2-results__option--group {
              padding: 0;
            }

            &:first-child {
              display: none;
            }

            &.select2-results__option--highlighted {
              color: $white;
              background-color: $primary;
            }
        }
      }
    }
  }
}

.check-row {
  display: flex;
}

.custom-check-element {
  position: relative;
  margin: 0 6px 0 0;

  .btn {
    position: relative;
    padding: 9px 6px;
    font-size: 1rem;
    font-weight: $font-weight-normal;

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      width: 10px;
      height: 7px;
      opacity: 0;
      visibility: hidden;
      transition:opacity $transition-duration-base $transition-easing-base, visibility $transition-duration-base $transition-easing-base;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7'><path d='M9.47322 0.306574C9.41124 0.244088 9.33751 0.194492 9.25627 0.160646C9.17503 0.1268 9.08789 0.109375 8.99989 0.109375C8.91188 0.109375 8.82474 0.1268 8.7435 0.160646C8.66226 0.194492 8.58853 0.244088 8.52655 0.306574L3.55989 5.27991L1.47322 3.18657C1.40887 3.12441 1.33291 3.07554 1.24967 3.04274C1.16644 3.00993 1.07755 2.99385 0.9881 2.99539C0.898646 2.99694 0.810373 3.01609 0.728321 3.05176C0.646269 3.08742 0.572044 3.13889 0.509885 3.20324C0.447726 3.26759 0.39885 3.34355 0.366047 3.42679C0.333245 3.51002 0.317158 3.59891 0.318705 3.68836C0.320253 3.77781 0.339405 3.86609 0.375067 3.94814C0.41073 4.03019 0.462204 4.10441 0.526552 4.16657L3.08655 6.72657C3.14853 6.78906 3.22226 6.83865 3.3035 6.8725C3.38474 6.90635 3.47188 6.92377 3.55989 6.92377C3.64789 6.92377 3.73503 6.90635 3.81627 6.8725C3.89751 6.83865 3.97124 6.78906 4.03322 6.72657L9.47322 1.28657C9.54089 1.22415 9.59489 1.14838 9.63183 1.06404C9.66877 0.979712 9.68784 0.888642 9.68784 0.796574C9.68784 0.704506 9.66877 0.613436 9.63183 0.529103C9.59489 0.44477 9.54089 0.369002 9.47322 0.306574Z' fill='white'/></svg>");
      content:'';
    }

    &.btn-gray-dark {
      border-color: $border-color;
    }
  }

  .btn-check {
    &:checked {
      + .btn {
        border-color: $border-color;
        padding-right: 25px;
        padding-left: 5px;

        &:before {
          opacity: 1;
          visibility: visible;
        }

        &.btn-outline-check {
          background-color: $primary;
          color: $white;
        }
      }
    }

    + .btn-outline-check:hover {
      background-color: $primary;
      color: $white;
      border-color: $border-color;
    }
  }
}

.btn-outline-check {
  color: $gray-800;
  border-color: $border-color;
}

.invalid-feedback {
  margin: 0 0 20px;
  background-color: $form-feedback-invalid-color;
  border-radius: 6px;
  color: $white;
  padding: 16px 35px 16px 16px;
  font-size: 15px;
  position: relative;

  .btn-close {
    width: 24px;
    height: 24px;
    background: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    color: rgba($white, 0.75);
    opacity: 1;
    filter:none;
    
    svg {
      path {
          @include transition(color);
      }
  }

    &:hover {
      color: $white;
      opacity: 1;
    }
  }
}

.login-form {
  width: 440px;
  margin: 0 auto;
  font-size: 15px;
  padding: 20px 0;

  .note-text {
    display: block;
    color: $gray-800;
    padding: 0 0 16px;
  }

  .form-row {
    padding-bottom: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .form-control {
    height: 52px;
    font-size: 15px;
    border-color: $gray-300;
    margin-bottom: 0;
    border-radius: 6px;
    padding-left: 16px;
    padding-right: 35px;
    color: $gray-800;

    @include placeholder {
      color: $gray-300;
    }
  }

  .form-label {
    font-weight: $font-weight-normal;
    margin-bottom: 10px;
    font-size: 15px;
  }

  .input-holder {
    position: relative;
    width: 100%;
  }

  .togglePassword {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    background: none;
    padding: 0;
    border: none;

    &.show {
      i {
        &:before {
          content:"\f070";
        }
      }
    }
  }

  .btn {
    display: block;
    width: 100%;
    color: $white;
    font-size: 16px;
    padding: 16px;
    border-radius: 6px;
    margin: 14px 0 0;
  }

  &.was-validated {
    .form-control {
      &:invalid,
      &.is-invalid {
        border-color: $gray-300;
      }
    }

    .form-check-input:valid ~ .form-check-label,
    .form-check-input.is-valid ~ .form-check-label {
      color: $form-check-label-color;
    }

    .form-control:valid,
    .form-control.is-valid {
      border-color: $gray-300;
    }

    .form-check-input:valid,
    .form-check-input.is-valid {
      border-color: $gray-300;
    }
  }
}

.form-check-input {
  margin-top: 0;
}

.create-form {
  padding: 22px 0 0;

  .form-control {
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
    color: $gray-800;

    @include placeholder {
      opacity: 1;
      color: $blue-200;
      font-weight: $font-weight-normal;
    }

    &.filled {
      border-color: $primary;
    }
  }

  .form-label {
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
    margin-bottom: 12px;
  }

  .form-check-label {
    font-size: 1.1rem;
  }

  .form-check-input {
    width: 14px;
    height: 14px;
  }
}

.required-item {
  color: $danger;
}

.form-switch {
  margin-bottom: 0.4rem;
}

.distributor-form {
  .form-label {
    font-weight: normal;
    font-size: 1.2rem;
    margin-bottom: 2px;
  }

  .form-control,
  .select2-container .select2-selection--single,
  .select2-container .select2-selection--multiple {
    border-color: $border-color;
    margin-bottom: 16px;
  }

  .select2-container .select2-selection--single .select2-selection__arrow b,
  .select2-container .select2-selection--multiple .select2-selection__arrow b {
    border-top-color: #888;
    border-width: 5px 4px 0 4px;
  }

  .select2-container {
    &.select2-container--focus {
      .select2-selection--single,
      .select2-selection--multiple {
        outline: 3px solid rgba($primary, 0.3);
        border-bottom-left-radius: $form-select-border-radius;
        border-bottom-right-radius: $form-select-border-radius;
        border-color: $primary;
      }
    }
  }

  textarea {
    &.form-control {
      min-height: 76px;
      height: 76px;
    }
  }

  input[type="file"] {
    &.form-control {
      margin: 0;
      font-size: 1.2rem;

      &::file-selector-button {
        padding: $input-padding-y $input-padding-x;
        margin: -5px 10px -5px -10px;
        height: 34px;
        // margin-inline-end: 0; //8-05
      }
    }
  }

  .btn {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    min-width: 80px;
  }

  .submit-holder {
    border-top: 1px solid $border-color;
    padding-bottom: 12px;
  }
}

// #8-01
label{
  &.required {
    &:after {
      color: #fb434a;
      content: ' *';
      display: inline;  
    }
  }
}

// #8-02
.input-group {
  .form-control {
    margin-bottom: 0; 
  }
}


.form-control {
  color: $gray-800; // #10-06

  &:focus,
  &:active {
    color: $gray-800; // #10-06
  }

  &:disabled {
    background-color: $gray-100; // #8-03
  }
}

// #8-04
.form-select {
  border-color: $input-border-color;
}

// #10-07
.form-row {
  &.gx-sm {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(33.3333% + 10px);

    .form-col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-color: $blue-200;
}