// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------
.nav-item {
  margin: 0;

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: $nav-link-hover-color;
  }
}

.navbar {
  padding-bottom: 11px;
}

// #6-01
.navbar-brand {
  margin-top: -10px;
  margin-left: 5px;

  img {
    display: block;
    width: 100%;
    max-width: 150px;
    max-height: 30px;
  }
}

.search-form {
  border-bottom: 1px solid $blue-200;
  width: 25%;

  .form-control {
    font-size: 14px;
    color: $gray-500;
    border: none;
    border-radius: 0;
    padding: 5px 0;
    background: none;
    height: auto;
    margin: 0;
  }

  button {
    &[type='submit'] {
      background: none;
      border: none;
      padding: 0;
      font-size: 16px;
      color: $gray-500;
      margin: 0 0 0 10px;
    }
  }
}

.user-block {
  display: flex;
  align-items: center;

  .user-button {
    font-size: 25px;
    color: $gray-800;
    margin: 0 0 0 4vw;
    padding: 0;
    background: none;
    border: none;

    &.dropdown-toggle {
      &:after {
        display: none;
      }

      &.show {
        background: none;
      }
    }
  }

  .dropdown-menu {
    left: auto;
    right: 7px;
    margin-top: 5px !important;

    .dropdown-header {
      text-transform: none;
    }

    .fi {
      font-size: 9px;
      margin: 0 2px 0 0;
      border-radius: 2px;
    }
  }
}

.header {
  width: 100%;

  .navbar-nav {
    margin-top: -20px;
    margin-bottom: -11px;

    .icon {
      vertical-align: top;
      display: inline-block;
    }
  }

  .navbar {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav-link {
    font-size: 17px;
    height: 63px;
    align-items: center;
    display: flex;
    padding-left: 15px !important;
    padding-right: 15px !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  .dropdown-menu {
    margin-top: 0;
    font-size: 1.2rem;

    a {
      font-weight: $font-weight-medium;
      opacity: 1;
    }
  }

  .dropdown-toggle {
    &:after {
      color: $gray-300;
      vertical-align: 3px;
      margin-left: 8px;
    }

    &.show {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}
