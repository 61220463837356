.footer {
    background-color: $blue-200;
    border-radius: $border-radius;
    padding: 42px 19px 38px;
    font-size: 1.1rem;
    color: $gray-800;
    margin: 0 5px;

    p {
        margin: 0;
    }

    img {
        margin: 0 20px 0 0;
    }
}

.contact-button {
    color: $white;
    font-weight: $font-weight-medium;
    line-height: 1;
    font-size: 1.2rem;
    padding: 5px 12px;
    border-radius: 46px;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: $gray-800;
    text-decoration: none;
    margin: 0 0 0 31px;

    i {
        border-radius: $rounded-circle;
        width: 32px;
        height: 32px;
        font-size: 1.6rem;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        margin: 0 8px 0 -13px;
    }

    &:hover {
        background-color: $primary;
        color: $white;
    }
}
